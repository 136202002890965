<template>
  <div>
    <b-table
      responsive
      show-empty
      striped
      hover
      small
      empty-text="No matching records found"
      style="text-align: center; font-size: small;"
      :items="filteredItems"
      :fields="fields"
      :tbody-tr-class="getRowClass"
    >
      <template #thead-top>
        <!-- First Row -->
        <b-tr>
          <b-th
            rowspan="2"
            class="text-center"
          >
            상위
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            아이디
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            닉네임
          </b-th>
          <b-th
            rowspan="2"
            class="text-center"
          >
            이름
          </b-th>
          <b-th
            colspan="2"
            variant="secondary"
            class="text-center"
          >
            루징
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            스포츠
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            카지노
          </b-th>
          <b-th
            colspan="2"
            variant="primary"
            class="text-center"
          >
            슬롯
          </b-th>
        </b-tr>
        <!-- Second Row -->
        <b-tr>
          <b-th
            class="text-center"
          >
            비율
          </b-th>
          <b-th
            class="text-center"
          >
            상위 비율
          </b-th>
          <b-th
            class="text-center"
          >
            비율
          </b-th>
          <b-th
            class="text-center"
          >
            상위 비율
          </b-th><b-th
            class="text-center"
          >
            비율
          </b-th>
          <b-th
            class="text-center"
          >
            상위 비율
          </b-th><b-th
            class="text-center"
          >
            비율
          </b-th>
          <b-th
            class="text-center"
          >
            상위 비율
          </b-th>
        </b-tr>
      </template>
      <!-- Column: recommendNickname -->
      <template #cell(recommendNickname)="data">
        <div
          class="d-flex align-items-center"
          style="width: 3rem; white-space: nowrap;"
        >
          <!-- style="min-width: 2rem" -->
          <b-badge
            :variant="`light-primary`"
            style="width: 3rem; margin-left: 5px; margin-right: 5px"
          >
            상위
          </b-badge>
          <span> {{ data.item.recommendNickname }} </span>
        </div>
      </template>
      <!-- Column: user -->
      <template #cell(userid)="data">
        <div
          style="width: 3rem; margin-right: 5px"
        >
          <div
            class="d-flex align-items-center"
            :style="{ paddingLeft: `${(data.item.level || 0) * 5}px` }"
          >
            <span style="margin-right: 5px">
              {{ data.index === filteredItems.length - 1 ? '▸' : '▾' }}
            </span>
            <span
              style="width: 2rem; margin-right: 5px"
            >
              <b-avatar
                size="24"
                :src="data.item.role"
                :text="avatarText(data.item.role)"
                :variant="`${resolveUserRoleVariant(data.item.role)}`"
              />
            </span>
            <span>
              {{ data.item.userid }}
            </span>
          </div>
        </div>
      </template>
      <!-- Column: rate -->
      <template #cell(rate)="data">
        <div>
          <span
            :style="{
              fontWeight: data.item.validRate === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.rate != null ? data.item.rate : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: upperRate -->
      <template #cell(upperRate)="data">
        <div
          v-b-tooltip.hover.bottom.v-danger="data.item.upperRateMsg"
        >
          <span
            :style="{
              color: data.item.validRate === false ? 'red' : 'inherit',
              fontWeight: data.item.validRate === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.upperRate != null ? data.item.upperRate : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: rateSportRolling -->
      <template #cell(rateSportRolling)="data">
        <div>
          <span
            :style="{
              fontWeight: data.item.validRateSportRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.rateSportRolling != null ? data.item.rateSportRolling : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: upperRateSportRolling -->
      <template #cell(upperRateSportRolling)="data">
        <div
          v-b-tooltip.hover.bottom.v-danger="data.item.upperRateSportRollingMsg"
        >
          <span
            :style="{
              color: data.item.validRateSportRolling === false ? 'red' : 'inherit',
              fontWeight: data.item.validRateSportRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.upperRateSportRolling != null ? data.item.upperRateSportRolling : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: rateCasinoRolling -->
      <template #cell(rateCasinoRolling)="data">
        <div>
          <span
            :style="{
              fontWeight: data.item.validRateCasinoRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.rateCasinoRolling != null ? data.item.rateCasinoRolling : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: upperRateCasinoRolling -->
      <template #cell(upperRateCasinoRolling)="data">
        <div
          v-b-tooltip.hover.bottom.v-danger="data.item.upperRateCasinoRollingMsg"
        >
          <span
            :style="{
              color: data.item.validRateCasinoRolling === false ? 'red' : 'inherit',
              fontWeight: data.item.validRateCasinoRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.upperRateCasinoRolling != null ? data.item.upperRateCasinoRolling : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: rateSlotRolling -->
      <template #cell(rateSlotRolling)="data">
        <div>
          <span
            :style="{
              fontWeight: data.item.validRateSlotRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.rateSlotRolling != null ? data.item.rateSlotRolling : '-' }}
          </span>
        </div>
      </template>
      <!-- Column: upperRateSlotRolling -->
      <template #cell(upperRateSlotRolling)="data">
        <div
          v-b-tooltip.hover.bottom.v-danger="data.item.upperRateSlotRollingMsg"
        >
          <span
            :style="{
              color: data.item.validRateSlotRolling === false ? 'red' : 'inherit',
              fontWeight: data.item.validRateSlotRolling === false ? 'bold' : 'normal'
            }"
          >
            {{ data.item.upperRateSlotRolling != null ? data.item.upperRateSlotRolling : '-' }}
          </span>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BTr, BTh,
  BBadge, BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_BRANCH_RATE } from '@/store/branch/action'

const branchStore = createNamespacedHelpers('branchStore')

export default {
  name: 'BranchRate',
  components: {
    BTable,
    BTr,
    BTh,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    enrollUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,

      fields: [
        { key: 'recommendNickname', label: '상위', thClass: 'd-none' },
        { key: 'userid', label: '아이디', thClass: 'd-none' },
        { key: 'nickname', label: '닉네임', thClass: 'd-none' },
        { key: 'username', label: '이름', thClass: 'd-none' },
        { key: 'rate', label: '비율', thClass: 'd-none' },
        { key: 'upperRate', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSportRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSportRolling', label: '상위', thClass: 'd-none' },
        { key: 'rateCasinoRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateCasinoRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSlotRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSlotRolling', label: '상위 비율', thClass: 'd-none' },
      ],
      rateFields: [
        'rate',
        'upperRate',
        'rateSportRolling',
        'upperRateSportRolling',
        'rateCasinoRolling',
        'upperRateCasinoRolling',
        'rateSlotRolling',
        'upperRateSlotRolling',
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...branchStore.mapGetters({
      fetchBranchRate: 'fetchBranchRate',
    }),
    filteredItems() {
      let filteredData = []
      filteredData = this.fncCalculateRate(this.fetchBranchRate, this.enrollUser)
      return filteredData
    },
    highlightedRow() {
      return this.enrollUser.userid
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchRate: FETCH_BRANCH_RATE,
    }),
    async fetchData() {
      await this.$fetchBranchRate({
        site: this.siteSelected,
        userid: this.userData.userid,
      })
    },
    validateRate(data) {
      // 각각의 값에 대한 로그 출력
      console.log('validRate:', data.validRate)
      console.log('validRateSportRolling:', data.validRateSportRolling)
      console.log('validRateCasinoRolling:', data.validRateCasinoRolling)
      console.log('validRateSlotRolling:', data.validRateSlotRolling)

      this.$emit('validate-rate', {
        isUpperRate: data.validRate,
        isUpperRateSportRolling: data.validRateSportRolling,
        isUpperRateCasinoRolling: data.validRateCasinoRolling,
        isUpperRateSlotRolling: data.validRateSlotRolling,
      })
    },
    fncCalculateRate(fetchData, userData) {
      // #.결과데이터 초기화
      let resultNode = []

      // #.fetchData, userData 병합
      const data = [...fetchData, userData]

      // #.map으로 변환
      const dataMap = {}
      data.forEach(item => {
        dataMap[item.nickname] = {
          site: item.site,
          userid: item.userid,
          username: item.username,
          nickname: item.nickname,
          recommendNickname: item.recommendNickname,
          role: item.role,
          rate: item.rate,
          rateSportRolling: item.rateSportRolling,
          rateCasinoRolling: item.rateCasinoRolling,
          rateSlotRolling: item.rateSlotRolling,
        }
      })
      console.log(dataMap)

      // #.부모노드 탐색
      function fncFindParent(node) {
        console.log(node.userid)
        // #.각 속성별 상위 노드와의 요율 계산
        // const _getUpperRate = attribute => {
        //   let upperRate = null
        //   let upperRateMsg = null
        //   let validRate = true
        //   console.log(dataMap[node.recommendNickname] && dataMap[node.recommendNickname][attribute] != null && dataMap[node.nickname][attribute] != null)
        //   if (dataMap[node.recommendNickname] && dataMap[node.recommendNickname][attribute] != null && dataMap[node.nickname][attribute] != null) {
        //     upperRate = parseFloat((dataMap[node.recommendNickname][attribute] - dataMap[node.nickname][attribute]).toFixed(3))
        //     upperRateMsg = `${dataMap[node.recommendNickname][attribute]} - ${dataMap[node.nickname][attribute]}`
        //     validRate = upperRate >= 0
        //   }

        //   return {
        //     upperRate,
        //     upperRateMsg,
        //     validRate,
        //   }
        // }
        // const getUpperRate = attribute => {
        //   let upperRate = null
        //   let upperRateMsg = null
        //   let validRate = true

        //   // 현재 노드의 값 가져오기
        //   const currentValue = dataMap[node.nickname][attribute]

        //   // 현재 값이 유효한 숫자인지 확인
        //   if (currentValue != null && !Number.isNaN(Number(currentValue))) {
        //     const parentNode = dataMap[node.recommendNickname]

        //     // 상위 추천인이 존재하고, 최상위 노드(admin)가 아닌 경우만 비교
        //     if (parentNode && parentNode.role !== 'admin') {
        //       const upperValue = parentNode[attribute]

        //       // 상위 비율과 현재 비율이 모두 유효한 숫자인 경우에만 계산
        //       if (upperValue != null && !Number.isNaN(Number(upperValue))) {
        //         upperRate = parseFloat((upperValue - currentValue).toFixed(3))
        //         upperRateMsg = `${upperValue} - ${currentValue}`
        //         validRate = currentValue >= 0 && upperRate >= 0 // 상위 비율보다 크지 않아야 함
        //       }
        //     } else {
        //     // 최상위 노드(admin)라면 상위 비율 비교 없이 그대로 적용, 단 음수만 방지
        //       upperRate = null
        //       upperRateMsg = `최상위 노드(admin), 입력값 유지: ${currentValue}`
        //       validRate = currentValue >= 0
        //     }
        //   }

        //   return {
        //     upperRate,
        //     upperRateMsg,
        //     validRate,
        //   }
        // }
        const getUpperRate = attribute => {
          const parentNode = dataMap[node.recommendNickname]
          const currentValue = Number(dataMap[node.nickname][attribute])
          const upperValue = parentNode ? Number(parentNode[attribute]) : null

          // 입력값이 유효한 숫자가 아니면 계산하지 않음
          if (Number.isNaN(currentValue)) return { upperRate: null, upperRateMsg: null, validRate: false }

          // 입력값이 음수이면 상위 비율을 그대로 유지
          if (currentValue < 0) {
            return {
              upperRate: upperValue,
              upperRateMsg: `입력값 음수 → 상위 비율 유지: ${upperValue}`,
              validRate: false,
            }
          }

          // 최상위 노드(admin)일 경우 입력값 그대로 적용
          if (parentNode?.role === 'admin') {
            return {
              upperRate: currentValue,
              upperRateMsg: `최상위 노드(admin), 입력값 유지: ${currentValue}`,
              validRate: true,
            }
          }

          // 상위 비율이 유효한 숫자인 경우에만 계산
          if (!Number.isNaN(upperValue) && upperValue !== null) {
            const upperRate = parseFloat((upperValue - currentValue).toFixed(3))
            return {
              upperRate,
              upperRateMsg: `${upperValue} - ${currentValue}`,
              validRate: upperRate >= 0,
            }
          }

          // 기본값 반환 (상위 비율이 없는 경우)
          return { upperRate: null, upperRateMsg: null, validRate: true }
        }

        // #.상위 노드 정보 가져오기
        const parentNode = dataMap[node.recommendNickname]

        // #.각 속성별 상위 노드와의 요율 계산
        const { upperRate, upperRateMsg, validRate } = getUpperRate('rate')
        const { upperRate: upperRateSportRolling, upperRateMsg: upperRateSportRollingMsg, validRate: validRateSportRolling } = getUpperRate('rateSportRolling')
        const { upperRate: upperRateCasinoRolling, upperRateMsg: upperRateCasinoRollingMsg, validRate: validRateCasinoRolling } = getUpperRate('rateCasinoRolling')
        const { upperRate: upperRateSlotRolling, upperRateMsg: upperRateSlotRollingMsg, validRate: validRateSlotRolling } = getUpperRate('rateSlotRolling')

        dataMap[node.nickname].upperRate = upperRate
        dataMap[node.nickname].upperRateMsg = upperRateMsg
        dataMap[node.nickname].validRate = validRate
        dataMap[node.nickname].upperRateSportRolling = upperRateSportRolling
        dataMap[node.nickname].upperRateSportRollingMsg = upperRateSportRollingMsg
        dataMap[node.nickname].validRateSportRolling = validRateSportRolling
        dataMap[node.nickname].upperRateCasinoRolling = upperRateCasinoRolling
        dataMap[node.nickname].upperRateCasinoRollingMsg = upperRateCasinoRollingMsg
        dataMap[node.nickname].validRateCasinoRolling = validRateCasinoRolling
        dataMap[node.nickname].upperRateSlotRolling = upperRateSlotRolling
        dataMap[node.nickname].upperRateSlotRollingMsg = upperRateSlotRollingMsg
        dataMap[node.nickname].validRateSlotRolling = validRateSlotRolling

        // 부모 노드가 없으면 종료(위치변경X)
        if (!parentNode) return

        resultNode.unshift(parentNode)
        fncFindParent(parentNode)
      }
      fncFindParent(userData)
      resultNode.push(dataMap[userData.nickname]) // 등록할 유저 추가
      // .fncfindParent

      // #. Node Depth 구하기
      function fncFindDepth(node) {
        // #.map으로 변환
        const nodeMap = {}
        node.forEach(item => {
          nodeMap[item.nickname] = { ...item, level: 0 }
        })

        // 각 노드의 level을 계산
        function setLevel(currentNickname) {
          const currentNode = nodeMap[currentNickname]
          if (!currentNode) return 0 // 노드가 없으면 0 반환

          // 현재 노드에 이미 level이 계산되었다면 바로 반환
          if (currentNode.level > 0) return currentNode.level

          // 상위 노드의 level을 가져와 현재 노드의 level 계산
          if (currentNode.recommendNickname && nodeMap[currentNode.recommendNickname]) {
            currentNode.level = setLevel(currentNode.recommendNickname) + 1
          } else {
            currentNode.level = 0 // 최상위 노드는 level 0
          }
          return currentNode.level
        }

        // 모든 노드에 대해 level 설정
        Object.keys(nodeMap).forEach(nickname => {
          setLevel(nickname)
        })

        return Object.values(nodeMap) // 결과 반환
      }
      resultNode = fncFindDepth(resultNode)

      this.validateRate(dataMap[userData.nickname])

      return resultNode
    },
    /* [formatter] */
    formatRate(value) {
      return value !== null ? value : ''
    },
    formatAuthSites(authSites) {
      return Array.isArray(authSites) && authSites.length > 0 ? authSites.join(', ') : ''
    },
    getRowClass(item) {
      return item.userid === this.highlightedRow ? 'highlight-row' : ''
    },
    getStyle(value) {
      return {
        color: value < 0 ? 'red' : 'inherit', // 음수일 경우 빨간색, 그렇지 않으면 기본 색상
        fontWeight: value < 0 ? 'bold' : 'normal',
      }
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
  },
}

</script>

<style>
.highlight-row {
  background-color: #464251 !important; /* 원하는 하이라이트 색상으로 변경 */
}
.b-table > tbody > tr.highlight-row:hover {
      background-color: #464251 !important;
}
</style>
